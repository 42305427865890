






















































































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import PersonFilterDTO from "@/dto/person/PersonFilterDTO";
import PersonDTO from "@/dto/person/PersonDTO";
import PersonService from "@/services/PersonService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import RegexUtils from "@/utils/RegexUtils";
import RouteNames from "@/router/RouteNames";
import AdminLinksModal from "@/components/profile/admin/AdminLinksModal.vue";
import {fromPerson, WorkspaceTypePathElement} from "@/dto/auth/Workspace";
import PersonCreationComponent from "@/components/profile/admin/person/PersonCreationComponent.vue";
import AdminCreationModal from "@/components/profile/admin/admin/AdminCreationModal.vue";
import PaginationComponent from "@/components/util/PaginationComponent.vue";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
  computed: {
    WorkspaceTypePathElement() {
      return WorkspaceTypePathElement
    }
  },
    components: {PaginationComponent}
})
export default class AdminList extends Vue {
    private RouteNames = RouteNames;

    @AppModule.Action
    private startLoading!: () => void

    @AppModule.Action
    private stopLoading!: () => void

    @Auth.Getter("hasAdministrativePrivileges")
    private hasAdministrativePrivileges!: boolean;

    private filter = new PersonFilterDTO();

    private persons: Array<PersonDTO> = [];

    private totalPages = 1;

    private message = "";

    private successful = true;

    mounted() {
        this.filter.onlyAdmins = true;
        if (!this.hasAdministrativePrivileges) {
            this.$router.push({name: RouteNames.HOME});
        } else {
            this.loadPersons();
        }
    }

    handleUrl(person: PersonDTO) {
        return '/userprofile/p/' + person.id;
    }

    loadPersons() {
        this.startLoading();
        this.message = "";
        PersonService.getByFilter(this.filter).then(
            (response) => {
                this.persons = response.data.data;
                this.totalPages = response.data.countOfPages;
                this.successful = true;
            },
            (error) => {
                this.successful = false;
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
            }
        ).then(() => {
            this.stopLoading();
        })
    }

    changePage(page: number) {
        this.filter.pageNumber = page;
        this.loadPersons();
    }

    get getEmailSortIcon(): string {
        return this.getSortIcon("EMAIL");
    }

    get getFirstNameSortIcon(): string {
        return this.getSortIcon("FIRST_NAME");
    }

    get getLastNameSortIcon(): string {
        return this.getSortIcon("LAST_NAME");
    }


    getSortIcon(field: string) {
        if (this.filter.orderField !== field) {
            return "sort";
        } else if (this.filter.orderDirection === "ASC") {
            return "sort-up";
        } else {
            return "sort-down";
        }
    }

    switchOrder(field: string) {
        if (field === this.filter.orderField) {
            this.toggleDirection();
        } else {
            this.filter.orderField = field;
            this.filter.orderDirection = "ASC";
        }
        this.loadPersons();
    }

    toggleDirection() {
        this.filter.orderDirection = this.filter.orderDirection === "ASC" ? "DESC" : "ASC";
    }

    handleName(event: KeyboardEvent) {
        if (event.key != '\'' && event.key != "-" && !RegexUtils.testLatin(event.key)) {
            event.preventDefault();
        }
    }

    clearFilter() {
        this.filter = new PersonFilterDTO();
        this.loadPersons();
    }

    registerNewAdmin() {
        this.$modal.show(
            AdminCreationModal,
            {
                onSuccess: () => this.loadPersons()
            },
            {
                width: "50%",
                height: "auto",
                scrollable: true,
            }
        );
    }

}
